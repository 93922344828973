/* App.css */
body, html {
  margin: 10 ;
  padding: 10;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Arial', sans-serif; /* Use your preferred font */
  background-color: #f4f4f4; /* Light grey background */
}

#root {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  text-align: center;
  width: 70%; /* Set the max width for content */
  max-width: 600px; /* Maximum width of the content area */
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Subtle shadow for a bit of depth */
  background: #fff; /* White background for the content */
  border-radius: 8px; /* Rounded corners */
}

/* Add more styling as needed */


.background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 50%;
  height: 50%;
  background-image: url('/public/logo192.png');
  background-size: cover;
  animation: moveBackground 5s linear infinite;
}

@keyframes moveBackground {
  from {background-position: 0 0;}
  to {background-position: 90% 0;}
}

/* Add multiple layers with different speeds */
.background-slow {
  animation-duration: 40s;
}

.background-fast {
  animation-duration: 10s;
}

.content {
  position: relative;
  z-index: 10;
  background: rgba(255, 255, 255, 0.8);
  margin: auto;
  padding: 20px;
  width: 90%;
  top: 50%; /* Center vertically */
  transform: translateY(-50%);
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  text-align: center;
}
